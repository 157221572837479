/* ---------- Global Scroll Bar Css Fixes ----------- */

::-webkit-scrollbar {
  width: 6.5px;
  height: 6.5px;
}

::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}

::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 30px;
}

/* For Firefox based browsers */
html {
  scrollbar-width: thin;
  scrollbar-color: #d9d9d9 #f2f2f2;
}
